import React from "react";
import { Link } from "react-router-dom";
import MobileMenu from "../../components/MobileMenu";
import logo3 from "../../images/logo/logo3.png";

import "./style.css";

const Header = () => {
  // const SubmitHandler = (e) => {
  //   e.preventDefault();
  // };

  return (
    <header>
      <div className="header-top-1">
        <div className="container">
          <div className="row">
            <div className="col-md-9 col-sm-12 col-12">
              <ul className="d-flex account_login-area">
                <li>
                  <i className="fa fa-clock-o" aria-hidden="true"></i>Lun - Ven
                  : 8.00 - 18.00
                </li>
                <li>
                  <i className="fa fa-map-marker"></i>Acae, Libreville, Gabon
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="header-style-1">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-10 col-sm-10 col-8 col-t">
              <div className="logo">
                <Link to="/">
                  <img src={logo3} alt="" />
                </Link>
              </div>
            </div>
            <div className="col-lg-8 d-none d-lg-block col-m">
              <div className="main-menu">
                <nav className="nav_mobile_menu">
                  <ul>
                    <li className="active">
                      <Link to="/">Accueil</Link>
                    </li>
                    <li>
                      <Link to="/about">A propos</Link>
                    </li>
                    <li>
                      <Link to="/servicesingle">Nos services</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact</Link>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
            <div className="col-lg-1 col-md-1 col-sm-1 col-2 search">
              <ul>
                {/* <li>
                  <Link to="/">
                    <i className="fa fa-search"></i>
                  </Link>
                  <ul>
                    <li>
                      <form onSubmit={SubmitHandler}>
                        <input type="text" placeholder="Rechercher..." />
                        <button type="btn">
                          <i className="fa fa-search"></i>
                        </button>
                      </form>
                    </li>
                  </ul>
                </li> */}
              </ul>
            </div>
            <div className="col-md-1 col-sm-1 col-2">
              <MobileMenu />
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
